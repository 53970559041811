<template>
  <div>
    
    <b-row v-if="role == 'superadmin' || role == 'admin'">
      <b-col md="4" sm="6" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">{{ $t("All customers") }}</label>
          <b-input-group size="sm" @click="getallcustomer">
            <b-form-select
              id="filterSelect"
              v-model="filterCustomerSelect"
              class="w-75"
              @change="filterCustomerSelectChanged"
            >
              <template>
                <option value="">-- {{ $t("Select customer") }} --</option>
                <option
                  :value="item.cusCode"
                  v-for="(item, idx) in allcustomer"
                  :key="idx"
                >
                  {{ item.cusName }}
                </option>
              </template>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-card>

      <b-row class="justify-content-center" v-if="role == 'superadmin' || role == 'admin' || cusData.empSummary == '1'" style="border-bottom: 1px solid #dee2e6;"> 

        <b-button-group class="mb-1">
          <b-button
            variant="primary"
          >
            <feather-icon icon="ClockIcon" class="mr-50" />
            <span class="font-weight-bold">{{ $t("Sort by date") }}</span>
          </b-button>
          <b-button
            variant="outline-primary"
            @click="$router.push('/report/checkin/member-summary')"
          >
            <feather-icon icon="UsersIcon" class="mr-50" />
            <span class="font-weight-bold">{{ $t("Sort by Employeer") }}</span>
          </b-button>
        </b-button-group>

      </b-row>

      <b-row>
        <b-col md="3" sm="6" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t("Date") }}</label>
            <feather-icon icon="CalendarIcon" size="24" />
            <date-range-picker
              class="input-group input-group-sm"
              v-model="dateRange"
              :opens="'right'"
              :locale-data="{
                format: 'dd/mm/yyyy',
                applyLabel: 'ตกลง',
                cancelLabel: 'ยกเลิก',
                daysOfWeek: ['อ.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],
                monthNames: [
                  'ม.ค.',
                  'ก.พ.',
                  'มี.ค.',
                  'เม.ย',
                  'พ.ค',
                  'มิ.ย.',
                  'ก.ค.',
                  'ส.ค.',
                  'ก.ย.',
                  'ต.ค.',
                  'พ.ย',
                  'ธ.ค.',
                ],
              }"
              :ranges="ranges"
              @update="updateDate"
            />
          </b-form-group>
        </b-col>

        <b-col md="2" sm="6" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50"
              >{{ $t("Filter product") }}</label
            >
            <b-input-group size="sm" @click="getAllSetup">
              <b-form-select
                id="filterProset"
                v-model="filterProset"
                class="w-75"
                @change="filterProSetSelectChanged"
              >
                <template>
                  <option value="">{{ $t("All") }}</option>
                  <option
                    :value="item.proSerial"
                    v-for="(item, idx) in allpro_setup"
                    :key="idx"
                  >
                    {{ item.setName }}
                  </option>
                </template>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="2" sm="6" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t("Filter") }}</label>
            <b-input-group size="sm">
              <b-form-select
                id="filterSelect"
                v-model="filterSelect"
                class="w-75"
                @change="filterSelectChanged"
              >
                <template>
                  <option value="">{{ $t("All") }}</option>
                  <option value="alcohol">{{ $t("Alcohol in body") }} > 0mg</option>
                  <option value="sys">{{ $t("Blood pressure") }} (SYS) < 90 หรือ > 140</option>
                  <option value="dia">{{ $t("Blood pressure") }} (DIA) < 60 หรือ > 90</option>
                  <option value="temp">{{ $t("Temp") }} > 37.5°C</option>
                  <option value="speed_foot">{{ $t("Speed foot Title") }} Fail</option>
                  <option value="speed_hand">{{ $t("Speed hand Title") }} Fail</option>
                  <option value="blind">{{ $t("Blind") }} Fail</option>
                </template>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="5" sm="6" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50"
              >{{ $t("Find employeer") }}</label
            >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                :placeholder="$t('Name')"
                @keyup="searchEnter()"
                @keydown="searchEnter()"
                @change="searchEnter()"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="searchClear()"
                  style="border-radius: 0 0.357rem 0.357rem 0"
                >
                {{ $t("Clear") }}
                </b-button>
              </b-input-group-append>

              <b-button
                style="margin-left: 5px; border-radius: 0.357rem"
                variant="primary"
                @click="showAllData"
                size="sm"
              >
                <span class="text-nowrap">{{ $t("Show all report") }}</span>
              </b-button>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="12" class="mb-1">
          <b-form-group class="mb-0">
            <span
              class="text-nowrap text-dark font-weight-bold mr-1"
              style="line-height: 2"
            >
            {{ $t("Chickin Total") }}: {{ totalRows }} {{ $t("Times") }}</span
            >
            <b-button
              size="sm"
              variant="success"
              class="mr-25"
              @click="exportExcelFile()"
              style="background-color: #157347 !important"
            >
              <feather-icon icon="FileTextIcon" class="mr-25" />
              <span class="text-nowrap">{{ $t("Download") }} </span>
              <b-spinner v-if="exportProcess" small></b-spinner>
            </b-button>
          </b-form-group>
        </b-col>
        
        <b-col cols="12">
          <b-table
            striped
            :sticky-header="true"
            responsive
            :per-page="perPage"
            :current-page="currentPagetable"
            :items="bigdata"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @sort-changed="sortingChanged"
            class="position-relative report_table"
            style="max-height: 70vh;padding-bottom: 75px;"
          >
            <template #cell(show_details)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                  </span>
                </span>
                <!-- <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span> -->
              </b-form-checkbox>
            </template>

            <template #row-details="row">
              <b-card>
                <b-button
                  size="sm"
                  variant="outline-secondary"
                  @click="row.toggleDetails"
                >
                  Hide Details
                </b-button>
              </b-card>
            </template>

            <template #cell(date)="data">
              {{ cvDate(data.item.checkin_at) }}
            </template>

            <template #cell(package)="data">
              {{ cvPackage(data.item.package) }}
            </template>

            <template #cell(checkin_type)="data">
                <span v-if="data.item.checkin_type == 1"><b-badge pill variant="info" style="background-color: #2196F3 !important;">{{ $t('Check In') }}</b-badge></span>
                <span v-else-if="data.item.checkin_type == 2"><b-badge pill variant="warning">{{ $t('Check Out') }}</b-badge></span>
                <span v-else>-</span>
            </template>

            <template #cell(photo)="data">
              <div v-if="data.item.photo" class="log-emp-photo" style="position: relative;">
                <b-img
                  fluid
                  :src="getImgUrl(data.item.photo)"
                  style="width: 50px;"
                />
              </div>
              <div v-else>
                <span>-</span>
              </div>
            </template>

            <template #cell(uid)="data">
              <b-link
                :to="{ name: 'chart-report', params: { id: data.item.uid } }"
                class="font-weight-bold d-block text-nowrap"
              >
                <span v-if="data.item.firstname">
                  {{ data.item.title }} {{ data.item.firstname }} &nbsp;&nbsp;
                  {{ data.item.lastname }}
                </span>
                <span v-else-if="data.item.tel">
                  {{ data.item.tel }}
                </span>
                <span v-else>-</span>
                <feather-icon
                  :id="'ic-chart_'+data.item.id"
                  style="cursor: pointer"
                  icon="ExternalLinkIcon"
                  size="14"
                  class="ml-50"
                />
                <b-tooltip :target="'ic-chart_'+data.item.id">{{ $t('View person graph') }}</b-tooltip>
              </b-link>
            </template>

            <template #cell(dep_name)="data">
                <div v-if="data.item.dep_name">
                  {{ data.item.dep_name }}
                </div>
                <div v-else>-</div>
            </template>

            <template #cell(position)="data">
                <div v-if="data.item.position">
                  {{ data.item.position }}
                </div>
                <div v-else>-</div>
            </template>

            <template #cell(alcohol)="data">
              <div v-if="data.item.package == 'al' || data.item.package == 'albp'">
                <div v-if="data.item.alcohol == 0" style="color: green">
                  {{ data.item.alcohol + " mg%" }}
                </div>
                <div v-else style="color: red;font-weight: bold;">
                  {{ data.item.alcohol + " mg%" }}
                </div>
              </div>
              <div v-else>-</div>
            </template>

            <template #cell(temp)="data">
              <!-- <div v-if="data.item.temp != null"> -->
              <div v-if="data.item.temp > 0 ">
                <div
                  v-if="data.item.temp > 37.5 "
                  style="color: red;font-weight: bold;"
                >
                  {{ data.item.temp + "°C" }}
                </div>

                <div
                  v-else-if="data.item.temp < 30 "
                  style="color: yellowgreen"
                >
                  {{ data.item.temp + "°C" + " "+ "LOW" }}
                </div>

                <div v-else-if="data.item.temp "   style="color: green">
                  {{ data.item.temp + "°C"  }}
                </div>
              </div>
              <div v-else>-</div>
            </template>

            <template #cell(sys)="data">
              <div v-if="data.item.package == 'albp' || data.item.package == 'bp'">
                <div
                  v-if="data.item.sys < 90 || data.item.sys > 140 "
                  style="color: red;font-weight: bold;"
                >
                  {{ data.item.sys  }}
                </div>

                <!-- <div
                  v-else-if="data.item.sys < 90 "
                  style="color: yellowgreen"
                >
                  {{ data.item.sys  }}
                </div> -->

                <div v-else-if="data.item.sys "   style="color: green">
                  {{ data.item.sys  }}
                </div>
              </div>
              <div v-else>-</div>
            </template>

            <template #cell(dia)="data">
              <div v-if="data.item.package == 'albp' || data.item.package == 'bp'">
                <div
                  v-if="data.item.dia < 60 || data.item.dia > 90"
                  style="color: red;font-weight: bold;"
                >
                  {{ data.item.dia  }}
                </div>

                <!-- <div
                  v-else-if="data.item.dia < 60 "
                  style="color: yellowgreen"
                >
                  {{ data.item.dia   }}
                </div> -->

                <div v-else-if="data.item.dia "   style="color: green">
                  {{ data.item.dia  }}
                </div>
              </div>
              <div v-else>-</div>
            </template>

            <template #cell(heart_rate)="data">
              <div v-if="data.item.package == 'albp' || data.item.package == 'bp'">
                {{ data.value + " bpm" }}
              </div>
              <div v-else>-</div>
            </template>

              <template #cell(speed_hand1)="data">
                <span v-if="data.item.option_hand == 1">
                  <span :class="[data.item.speed_hand1 > 0.75 ? 'text-red text-bold':'']">
                    <span v-if="data.item.speed_hand1 > 0">{{ data.item.speed_hand1 }}</span>
                    <span v-else>-</span>
                  </span>
                </span>
                <span v-else>-</span>
              </template>
              <template #cell(speed_hand2)="data">
                <span v-if="data.item.option_hand == 1">
                  <span :class="[data.item.speed_hand2 > 0.75 ? 'text-red text-bold':'']">
                    <span v-if="data.item.speed_hand2 > 0">{{ data.item.speed_hand2 }}</span>
                    <span v-else>-</span>
                  </span>
                </span>
                <span v-else>-</span>
              </template>
              <template #cell(speed_hand3)="data">
                <span v-if="data.item.option_hand == 1">
                  <span :class="[data.item.speed_hand3 > 0.75 ? 'text-red text-bold':'']">
                    <span v-if="data.item.speed_hand3 > 0">{{ data.item.speed_hand3 }}</span>
                    <span v-else>-</span>  
                  </span>
                </span>
                <span v-else>-</span>
              </template>
           
               <template #cell(speed_foot1)="data">
                <span v-if="data.item.option_foot == 1">
                  <span :class="[data.item.speed_foot1 > 0.75 ? 'text-red text-bold':'']">
                    <span v-if="data.item.speed_foot1 > 0">{{ data.item.speed_foot1 }}</span>
                    <span v-else>-</span>    
                  </span>
                </span>
                <span v-else>-</span>
              </template>

               
            <template #cell(speed_foot2)="data">
              <span v-if="data.item.option_foot == 1">
                  <span :class="[data.item.speed_foot2 > 0.75 ? 'text-red text-bold':'']">
                    <span v-if="data.item.speed_foot2 > 0">{{ data.item.speed_foot2 }}</span>
                    <span v-else>-</span>    
                  </span>
                </span>
                <span v-else>-</span>
            </template>
            <template #cell(speed_foot3)="data">
              <span v-if="data.item.option_foot == 1">
                  <span :class="[data.item.speed_foot3 > 0.75 ? 'text-red text-bold':'']">
                    <span v-if="data.item.speed_foot3 > 0">{{ data.item.speed_foot3 }}</span>
                    <span v-else>-</span>   
                  </span>
                </span>
                <span v-else>-</span>
            </template>

            <template #cell(blind)="data">
              <span v-if="data.item.option_blind == 1">
                <span :class="[data.item.blind < 5 ? 'text-red text-bold':'']">{{ (data.item.blind / 5) * 100 + "%" }}</span>
              </span>
              <span v-else>-</span>
            </template>

          

            <template #cell(action)="data">
              <feather-icon
                style="cursor: pointer"
                icon="BarChartIcon"
                size="16"
                @click="
                  $router.push({
                    name: 'chart-report',
                    params: { id: data.item.uid },
                  })
                "
              />
            </template>
          </b-table>
        </b-col>

        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t("Per page") }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              @change="perPageChanged()"
              class="w-50"
            />

            <div class="show-entries" v-if="totalRows == 0">
              <label class="text-muted">{{ $t("Showing") }} 0 {{ $t("To") }}  0 {{ $t("Of") }}  0 {{ $t("Entries") }} </label>
            </div>
            <div class="show-entries" v-else>
              <div v-if="currentPage * perPage > totalRows">
                <label class="text-muted"
                  >{{ $t("Showing") }} {{ currentPage * perPage + 1 - perPage }} {{ $t("To") }}
                  {{ currentPage * perPage }} {{ $t("Of") }} {{ totalRows }} {{ $t("Entries") }}</label
                >
              </div>
              <div v-else>
                <label class="text-muted"
                  >{{ $t("Showing") }} {{ currentPage * perPage + 1 - perPage }} {{ $t("To") }}
                  {{ currentPage * perPage }} {{ $t("Of") }} {{ totalRows }} {{ $t("Entries") }}</label
                >
              </div>
            </div>
          </b-form-group>
        </b-col>

        <b-col md="10" sm="8">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            @click.native="handlePageChange()"
            align="right"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BCardHeader,
  BCardText,
  BLink,
  BTable,
  BFormCheckbox,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BButtonGroup,
  BImg,
  BDropdown,
  BDropdownItem,
  BTabs, BTab,
  BSpinner,
  BTooltip
} from "bootstrap-vue";
import * as XLSX from "xlsx";
import jwt_decode from "jwt-decode";
import DateRangePicker from "vue2-daterange-picker";
import reportService from "@/services/reportService.js";
import customerService from "@/services/customerService.js";
import setupService from "@/services/setupService.js";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import dateFormat from "dateformat";
import moment from "moment";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardText,
    BLink,
    BTable,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BButtonGroup,
    BImg,
    BDropdown,
    BDropdownItem,
    BTabs, BTab,
    BSpinner,
    BTooltip,
    DateRangePicker,
  },
  data() {
    let startDate = new Date();
    let endDate = new Date();

    let today = new Date();
    let todayEnd = new Date();
    todayEnd.setHours(11, 59, 59, 999);

    // let startDate = new Date(today.getFullYear(), 0, 1);
    // let endDate = new Date(today.getFullYear(), 11, 31, 11, 59, 59, 999);

    let yesterdayStart = new Date();
    yesterdayStart.setDate(today.getDate() - 1);
    yesterdayStart.setHours(0, 0, 0, 0);

    let yesterdayEnd = new Date();
    yesterdayEnd.setDate(today.getDate() - 1);
    yesterdayEnd.setHours(11, 59, 59, 999);

    let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    let thisMonthEnd = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0,
      11,
      59,
      59,
      999
    );
    return {
      cusCode: null,
      role: "",
      filterCustomerSelect: "",
      dateRange: {
        startDate,
        endDate,
      },
      ranges: {
        วันนี้: [today, todayEnd],
        เมื่อวานนี้: [yesterdayStart, yesterdayEnd],
        เดือนนี้: [thisMonthStart, thisMonthEnd],
        ปีนี้: [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31, 11, 59, 59, 999),
        ],
        เดือนที่แล้ว: [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0, 11, 59, 59, 999),
        ],
      },
      perPage: 20,
      pageOptions: [20, 30, 50, 100],
      totalRows: 1,
      currentPage: 1,
      currentPagetable: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "show_details",
          label: "",
          class: "text-center col-detail d-sm-none",
        },
        // {
        //   key: "action",
        //   label: "  action",
        //   sortable: true,
        //   class: "text-center col-like",
        // },
        {
          key: "date",
          label: this.$t('Date/Time'),
          sortable: true,
          sortDirection: "desc",
          class: "col-date_at",
        },
        {
          key: "checkin_type",
          label: this.$t('checkin_type'),
          class: "text-center col-chkin_type",
        },
        {
          key: "package",
          label: this.$t('Package'),
          class: "text-center",
        },
        {
          key: "photo",
          label: this.$t('Picture'),
          class: "text-center",
        },
        // {
        //   key: "result",
        //   label: "ผลลัพธ์",
        //   class: "text-center",
        // },
        {
          key: "uid",
          label: this.$t('Name'),
          sortable: true,
          class: "text-left",
        },
        {
          key: "dep_name",
          label: this.$t('Type'),
          sortable: true,
          class: "text-center",
        },
        {
          key: "position",
          label: this.$t('Position'),
          sortable: true,
          class: "text-center",
        },
        {
          key: "alcohol",
          label: this.$t('ALCOHOL'),
          sortable: true,
          class: "text-center col-like",
        },
        {
          key: "temp",
          label: this.$t("Temp"),
          sortable: true,
          class: "text-center col-like",
        },
        {
          key: "sys",
          label: this.$t("SYS"),
          sortable: true,
          class: "text-center col-like",
        },
        {
          key: "dia",
          label: this.$t("DIA"),
          sortable: true,
          class: "text-center col-like",
        },
        {
          key: "heart_rate",
          label: this.$t("Heart Rate"),
          sortable: true,
          class: "text-center col-like",
        },
        {
          key: "speed_hand1",
          label: this.$t("Speed hand")+" 1",
          sortable: true,
          class: "text-center col-like",
        },
        {
          key: "speed_hand2",
          label: this.$t("Speed hand")+" 2",
          sortable: true,
          class: "text-center col-like",
        },
        {
          key: "speed_hand3",
          label: this.$t("Speed hand")+" 3",
          sortable: true,
          class: "text-center col-like",
        },
        {
          key: "speed_foot1",
          label: this.$t("Speed foot")+" 1",
          sortable: true,
          class: "text-center col-like",
        },
        {
          key: "speed_foot2",
          label: this.$t("Speed foot")+" 2",
          sortable: true,
          class: "text-center col-like",
        },
        {
          key: "speed_foot3",
          label: this.$t("Speed foot")+" 3",
          sortable: true,
          class: "text-center col-like",
        },
        {
          key: "blind",
          label: this.$t("Blind"),
          sortable: true,
          class: "text-center col-like",
        },
        {
          key: "push_speed",
          label: "การทดสอบความไว",
          sortable: true,
          class: "text-center col-like",
        },
      ],
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
      filterAll: "",
      filterProset: "",
      filterProset_q: "",
      filterSelect: "",
      filterSelect_q: "",
      filterSearch: "",
      filterDate: "",
      filterperPage: "&per_page=20",
      filtersortBy: "",
      filtersortDesc: "",
      bigdata: [],
      allcustomer: [],
      allpro_setup: [],
      modelOption: [],
      hideColfield: "",
      cusData: {},
      exportProcess: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {},
  methods: {
    chkModelOption() {
      if (this.modelOption) { 
        this.fields.find((value, index) => {
          if (value['key'] === 'temp') {
            let position = this.modelOption.indexOf('temp');
            
            if (position == -1){
              delete this.fields[index];
            }
          }
          
          if (value['key'] == 'sys') {
            let position = this.modelOption.indexOf('bp');
            if (position == -1){
              delete this.fields[index];
            }
          }

          if (value['key'] == 'dia') {
            let position = this.modelOption.indexOf('bp');
            if (position == -1){
              delete this.fields[index];
            }
          }

          if (value['key'] == 'heart_rate') {
            let position = this.modelOption.indexOf('bp');
            if (position == -1){
              delete this.fields[index];
            }
          }

          if (value['key'] == 'speed_hand1' || value['key'] == 'speed_hand2' || value['key'] == 'speed_hand3' ) {
            let position = this.modelOption.indexOf('sp_hand');
            if (position == -1){
              delete this.fields[index];
            }
          }
         
          if (value['key'] == 'speed_foot1' || value['key'] == 'speed_foot2' || value['key'] == 'speed_foot3' ) {
            let position = this.modelOption.indexOf('sp_foot');
            if (position == -1){
              delete this.fields[index];
            }
          }
          if (value['key'] == 'blind' ) {
            let position = this.modelOption.indexOf('blind');
            if (position == -1){
              delete this.fields[index];
            }
          }
          if (value['key'] == 'push_speed' ) {
            let position = this.modelOption.indexOf('push_sp');
            if (position == -1){
              delete this.fields[index];
            }
          }
        });
      }
    },
    chkhideCol() {
      //if (this.hideColfield) { 
        this.fields.find((value, index) => {
          if (value['key'] === 'dep_name') {
            let position = this.hideColfield.indexOf('department');
            
            if (position != -1){
              delete this.fields[index];
            }
          }
          
          if (value['key'] == 'position') {
            let position = this.hideColfield.indexOf('department');
            if (position != -1){
              delete this.fields[index];
            }
          }

        });
      //}
    },
    cvPackage(data) {
      // return data;
      switch (data) {
        case "full":
          return "Full";
          break;
        case "al":
          return "Alcohol";
          break;
        case "hr":
          return "HR & O2";
          break;
        case "bp":
          return "Blood Pressure";
          break;
        case "nobp":
          return "No Blood Pressure";
          break;
        default:
          return "Full";
          break;
      }
    },
    cvDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    filterCustomerSelectChanged() {
      this.cusCode = this.filterCustomerSelect;
      if (!this.filterCustomerSelect) {
        this.cusCode = null;
      }

      this.getbigdata();
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updateDate() {
      this.getbigdata();
    },
    filterDateRange() {
      var start = dateFormat(this.dateRange.startDate, "yyyy-mm-dd");
      var end = dateFormat(this.dateRange.endDate, "yyyy-mm-dd");
      if (this.dateRange.startDate && this.dateRange.endDate) {
        this.filterDate = "&startDate=" + start + "&endDate=" + end;
      } else {
        this.filterDate = "";
      }
    },
    filterProSetSelectChanged() {
      if (this.filterProset) {
        this.filterProset_q = "&proset_q=" + this.filterProset;
      } else {
        this.filterProset_q = "";
      }

      this.getbigdata();
    },
    filterSelectChanged() {
      if (this.filterSelect) {
        this.filterSelect_q = "&select_q=" + this.filterSelect;
      } else {
        this.filterSelect_q = "";
      }
      this.getbigdata();
    },
    searchEnter() {
      if (this.filter) {
        this.filterSearch = "&search=" + this.filter;
      } else {
        this.filterSearch = "";
      }
      this.getbigdata();
    },
    searchClear() {
      this.filter = "";
      this.filterSearch = "";
      this.getbigdata();
    },
    perPageChanged() {
      this.filterperPage = "&per_page=" + this.perPage;
      this.getbigdata();
    },
    sortingChanged(e) {
      if (e.sortBy) {
        this.filtersortBy = "&sortBy=" + e.sortBy;
      }

      if (e.sortDesc) {
        this.filtersortDesc = "&sortDesc=DESC";
      } else {
        this.filtersortDesc = "&sortDesc=ASC";
      }
      this.getbigdata();
    },
    handlePageChange() {
      this.getbigdata();
    },
    showAllData() {
      this.filterSelect = "";
      this.filter = "";
      this.filterSearch = "";
      this.filterProset_q = "";
      this.filterSelect_q = "";
      this.currentPage = 1;

      let today = new Date();
      let startDate = new Date("2022-01-01");
      let endDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        11,
        59,
        59,
        999
      );
      this.dateRange.startDate = startDate;
      this.dateRange.endDate = endDate;

      this.getbigdata();
    },
    getbigdata() {
      this.filterDateRange();
      var page = "page=" + this.currentPage;
      this.filterAll =
        "?" +
        page +
        this.filterSearch +
        this.filterProset_q +
        this.filterSelect_q +
        this.filterDate +
        this.filterperPage +
        this.filtersortBy +
        this.filtersortDesc;

      try {
        reportService
          .getalldata(this.cusCode, this.filterAll)
          .then((response) => {
            this.bigdata = response.data.data.data;
            this.totalRows = response.data.data.total;
            this.modelOption = response.data.modelOptions;
            this.hideColfield = response.data.col_hide;
            this.chkModelOption();
            this.chkhideCol();
            // this.blind = ((response.data.data.blind / 5) * 100).toFixed(0);
            // console.log(response);
          })
          .catch(() => {});
      } catch (e) {
        throw e;
      }
    },
    getallcustomer() {

      if (this.allcustomer.length == 0) {
        var page = "page=" + this.currentPage;
        this.filterAll =
          "?" +
          page +
          this.filterSearch +
          this.filterSelect_q +
          this.filterDate +
          this.filterperPage +
          this.filtersortBy +
          this.filtersortDesc;

        try {
          customerService
            .getalldata(this.filterAll)
            .then((response) => {
              this.allcustomer = response.data.data.data;
              //console.log(this.getalldata);
            })
            .catch(() => {});

          // this.data1 = response.data;
          // console.log(this.User_data);
        } catch (e) {
          // console.log(e);
        }
      }
    },
    getcusByCode() {
      try {
        customerService
          .getdataBycusCode(this.cusCode)
          .then((response) => {
            this.cusData = response.data.data
          })
          .catch(() => {});

        // this.data1 = response.data;
        // console.log(this.User_data);
      } catch (e) {
        // console.log(e);
      }
    },
    getAllSetup() {
      if (this.allpro_setup.length < 1) {
        try {
          setupService
            .getDataBycusCode(this.cusCode)
            .then((response) => {
              console.log(response.data.data);
              this.allpro_setup = response.data.data;
              // this.totalRows = this.bigdata.length;
            })
            .catch(() => {});
        } catch (e) {
          // console.log(e);
        }
      }
    },
    getImgUrl(pet) {
      var images = process.env.VUE_APP_UPLOAD_IMAGE; //"http://localhost/sci21/uploads/";
      if (!pet) {
        pet = "no-image.png";
      }
      return images + pet;
    },
    getIconUrl(pet) {
      var images = process.env.VUE_APP_UPLOAD_IMAGE; //"https://www.tod-gps.com/sci2/images/";
      return images + pet + ".png";
    },
    exportExcelFile() {

      this.exportProcess = true

      this.filterDateRange();
      var page = "page=" + this.currentPage;
      this.filterAll =
        "?" +
        page +
        this.filterSearch +
        this.filterProset_q +
        this.filterSelect_q +
        this.filterDate +
        this.filterperPage +
        this.filtersortBy +
        this.filtersortDesc;

      try {
        reportService
          .exporExcelLog(this.cusCode, this.filterAll)
          .then((response) => {
          
            this.exportProcess = false
            const link = document.createElement("a");
            link.href = process.env.VUE_APP_UPLOAD_IMAGE+'/export/'+response.data.filename;
            link.click();
          })
          .catch(() => {});
      } catch (e) {
        throw e;
      }

      // this.filterexportfile = "&export=excel";
      // this.getexceldata();
    },
    getexceldata() {
      this.filterDateRange();
      var page = "page=" + this.currentPage;

      this.filterAll =
        "?" +
        page +
        this.filterSearch +
        this.filterSelect_q +
        this.filterDate +
        this.filterperPage +
        this.filtersortBy +
        this.filtersortDesc +
        this.filterexportfile;
      try {
        reportService
          .getalldata(this.cusCode, this.filterAll)
          .then((response) => {
            if (response.data.data.length > 0) {
              console.log("data length > 0");

              let title = "";
              let heading = "";
              let filename = "";
              let data = "";
              let total = response.data.data.length;
              console.log(data.length);
              let date_title =
                moment(this.dateRange.startDate).format("DD/MM/YYYY") +
                " - " +
                moment(this.dateRange.endDate).format("DD/MM/YYYY");

              let customer = response.data.customer;
              //console.log(data.customer);

              let cusName = "";
              if (customer) {
                //console.log(response.data.customer);

                cusName = "ของ " + customer.cusName;
              }
              let filterBy = "แสดง : ทั้งหมด";

              if (this.filterSelect) {
                if (this.filterSelect == "alcohol") {
                  filterBy = "แสดงเฉพาะ : "+this.$t("Alcohol in body") +" > 0mg";
                } else if (this.filterSelect == "sys") {
                  filterBy = "แสดงเฉพาะ : "+this.$t("Blood pressure") +"(SYS) < 60 หรือ > 90 ";
                } else if (this.filterSelect == "dia") {
                  filterBy = "แสดงเฉพาะ : "+this.$t("Blood pressure")+" (DIA) < 60 หรือ > 90 ";
                } else if (this.filterSelect == "speed_hand") {
                  filterBy = "แสดงเฉพาะ : ประสาทสัมผัสมือ fail ";
                } else if (this.filterSelect == "speed_foot") {
                  filterBy = "แสดงเฉพาะ : ประสาทสัมผัสเท้า fail ";
                } else if (this.filterSelect == "blind") {
                  filterBy = "แสดงเฉพาะ : ตาบอดสี fail ";
                }
              }

              title = [
                [
                  "รายงานการใช้งาน " +
                    cusName +
                    " | ทั้งหมด " +
                    total +
                    " ครั้ง" +
                    " [" +
                    filterBy +
                    "] " +
                    " | ตั้งแต่วันที่ " +
                    date_title,
                ],
              ];

              heading = [
                [
                  "วัน/เวลา",
                  "Package",
                  "ชื่อ",
                  "แผนก",
                  "ตำแหน่ง",
                  "Alcohol",
                  "อุณหภูมิ",
                  // "ระดับออกซิเจน",
                  "SYS",
                  "DIA",
                  "อัตราการเต้นหัวใจ",
                  "การตอบสนองมือครั้งที่1",
                  "การตอบสนองมือครั้งที่2",
                  "การตอบสนองมือครั้งที่3",
                  "การตอบสนองเท้าครั้งที่1",
                  "การตอบสนองเท้าครั้งที่2",
                  "การตอบสนองเท้าครั้งที่3",
                  "การทดสอบสายตาบอดสี",
                  // "เครดิตที่ใช้ไป",
                ],
              ];

              filename = "export.xlsx";
              data = [];
              let i = 1;

              response.data.data.forEach((item) => {
                let sys = item.sys;
                sys = sys;
                let alcohol = item.alcohol;
                if (alcohol == null) {
                  alcohol = "-";
                } else {
                  alcohol = alcohol + " mg%";
                }

                data.push({
                  // num: i,
                  checkin: moment(item.checkin_at).format("DD/MM/YYYY HH:mm"),
                  Package: this.cvPackage(item.package),
                  fullname: item.firstname + " " + item.lastname,
                  dep_name: item.dep_name,
                  position: item.position,
                  alcohol: alcohol,
                  temp: item.temp,
                  sys: item.sys ,
                  dia: item.dia,
                  heart_rate: item.heart_rate + " bpm",
                  speed_hand1: item.speed_hand1,
                  speed_hand2: item.speed_hand2,
                  speed_hand3: item.speed_hand3,
                  speed_foot1: item.speed_foot1,
                  speed_foot2: item.speed_foot2,
                  speed_foot3: item.speed_foot3,

                  blind: item.blind,
                });

                i++;
              });

              let dat = {
                title: title,
                heading: heading,
                data: data,
                filename: filename,
              };

              this.exportExcel(dat);
            }
          })
          .catch(() => {});
      } catch (e) {
        throw e;
      }
    },
    exportExcel(data) {
      const wb = XLSX.utils.book_new();
      XLSX.utils.sheet_add_aoa(wb, data.title);
      XLSX.utils.sheet_add_aoa(wb, data.heading, { origin: "A2" });

      const dataWS = XLSX.utils.sheet_add_json(wb, data.data, {
        origin: "A3",
        skipHeader: true,
      });

      //wb["A6"].s = { fill: { fgColor: { rgb: "#111111" } } };


      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, data.filename);
    },
    showtoken() {
      var token = localStorage.getItem("token");
      var decoded = jwt_decode(token);
      this.role = decoded.role;

      if (decoded.role == "superadmin" || decoded.role == "admin") {
        this.cusCode = null;
      } else {
        this.cusCode = decoded.cusCode;
      }
    },
  },
  async created() {
    if (this.$route.query.date_st && this.$route.query.date_end) {
      this.dateRange.startDate = this.$route.query.date_st;
      this.dateRange.endDate = this.$route.query.date_end;
    }

    if (this.$route.query.filter) {
      this.filterSelect_q = "&select_q=" + this.$route.query.filter;
    }

    if (this.$route.query.proset_q) {
      this.filterProset_q = "&proset_q=" + this.$route.query.proset_q;
    }

    await this.showtoken();
    await this.getbigdata();

    if (this.role != "superadmin" && this.role != "admin") {
      await this.getcusByCode();
    }
    
  },
};
</script>

<style lang="scss">

@media screen and (min-width: 768px) {
  .daterangepicker.show-ranges[data-v-1ebd09d2] {
    min-width: 700px !important;
  }
}
.export-menu {
  text-align: right;
  text-align: right;
  margin-bottom: -35px;
  font-size: 25px;
}
.reportrange-text {
  padding: 8px 35px !important;
}

.feather.feather-calendar {
  position: absolute;
  margin-top: 25px;
  z-index: 1;
  margin-left: -25px;
}

.report_table .b-table {
  margin-top: 25px;
}

.daterangepicker.show-calendar {
  left: 0px !important;
}

.log-emp-photo {
  transition: all 0.5s ease-in-out;
}
.log-emp-photo:hover {
  transform: translateY(-4px) scale(6);
  z-index: 10;
}
.col-chkin_type {
  min-width: 70px;
  padding: 0.72rem 0.5rem !important;
}
.col-detail {
  width: 30px;
  padding: 5px !important;
}
.col-date_at {
  min-width: 100px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.col-like {
  min-width: 150px !important;
}

.text-red {
  color: red;
}
.text-bold {
  font-weight: bold;
}
// .vue-daterange-picker {
//   ::after {
//     padding-left: 20px !important;
//   }
//   ::before {
//     padding-left: 20px !important;
//   }
// }
</style>
