import http from "./http";

export default {
    getalldata(filterAll) {
        return http
            .get("setup" + filterAll)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    getdataById(id) {
        return http
            .get("setup/" + id)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            })
    },
    getDataBycusCode(id) {
        return http
            .get("setup-customer/" + id)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    getCustomerOptionsdata() {
        return http
            .get("customers-all")
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            })
    },
    insertData(form) {
        return http
            .post("users", form)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    insertCusSetup(id, form) {
        return http
            .post("setup/" + id, form)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    updateData(id, form) {
        return http
            .put("setup/" + id, form)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    updateFieldByCus(id, form) {
        return http
            .put("setup-customer/" + id, form)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    updateStatusNotify(id, notifyStatus) {
        return http
            .put("setup-notiy/" + id, notifyStatus)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    deleteData(id) {
        return http
            .delete("setup/" + id)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
 
    
   
}